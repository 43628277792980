
  
.tooltip {
  position: relative;
}
        
.tooltip .tooltiptext {
    display:none;
    background-color: #f4fb91;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    opacity: 0.7;
}
      
.tooltip:hover .tooltiptext {
    display:inline-block;
    opacity: 0.7;
    font-size:12px;
    color:black;
}
    
.popupJK {
    display:none;
    position:fixed;
    top:20%;
    width:30%;
    left:35%;
    padding: 20px;
    background-color: rgb(108, 108, 245);
    color: white;
    border-radius: 10px;
    opacity: 1;
    z-index: -1;
}

.puShow {
    display:block;
    z-index: 20;
}

.overlay {
    display:none;
    position:fixed;
    width:100vw;
    height:100vh;
    top:0px;
    left:0px;
    background-color: transparent;
    opacity: 0.1;
    z-index: -1;
}

.ovlShow {
    display:block;
    opacity: 0.1;
    z-index: 10;
}
            
                     